import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import CardLink from "../../../components/CardLink";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useStyles from "./WorkshopsCard.styles";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../../components/SnackbarContentWrapper";
import { storeErrorMessages } from "../../../redux/displayApiErrors/displayApiErrorsActions";
import { getWorkshopList } from "../../../redux/workshops/workshopActions";

const WorkshopsCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [openSuccess, setOpenSuccess] = useState(false);
  const [wsOpenSuccess, setWsOpenSuccess] = useState(false);
  const [workshopsToDisplay, setWorkshopsToDisplay] = useState([]);

  let count;

  const incrementCount = () => {
    count++;
  };

  // Below code is used to set workshopsToDisplay
  // -------------------------------------------------------------------------------------------------------------
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return month + "/" + day + "/" + year;
  };

  const formatDate = (dateInput) => {
    const initialDate = new Date(dateInput);
    const returnDate = new Date(
      initialDate.getTime() - initialDate.getTimezoneOffset() * -60000
    );
    return (
      returnDate.toLocaleString("default", { month: "long" }) +
      " " +
      returnDate.getDate()
    );
  };

  useEffect(() => {
    if (
      props.workshops !== undefined &&
      props.workshops.length > 0
    ) {

      if (workshopsToDisplay.length < props.workshops.length) {
        const workshopsToSet = props.workshops.slice(0, 3);
        workshopsToSet.forEach(workshop => {
          handleSetWorkshops(
            workshop.courseCode,
            workshop.courseTitle,
            workshop.startDate,
            workshop.endDate
          );
        });        
      } 
    }
  }, [props.workshops]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSetWorkshops = (courseCode, courseTitle, startDate, endDate) => {
    const initialStartDate = new Date(startDate);
    const registerDeadline = new Date(
      initialStartDate.getTime() - initialStartDate.getTimezoneOffset() * -60000
    );
    registerDeadline.setDate(registerDeadline.getDate() - 5);
    workshopsToDisplay.push({
      courseCode: courseCode,
      title: courseTitle,
      startDate: startDate,
      endDate: endDate,
      registerDeadline: getFormattedDate(registerDeadline),
    });
    props.setUpdate(props.update + 1);
  };
  // -------------------------------------------------------------------------------------------------------------

  // Below code is used to get workshops
  // -------------------------------------------------------------------------------------------------------------
  const setOneYearAhead = () => {
    let endDate = new Date();
    const newYear = endDate.getYear() + 1;
    endDate.setFullYear(1900 + newYear);
    return endDate;
  };

  const workshopObjectToRetrieveOneYear = {
    fq: "upcomingWorkshops",
    startDate: new Date(
      new Date().setTime(new Date().getTime() + 5 * 86400000)
    ).toLocaleDateString("en-US", { timeZone: "America/Phoenix" }),
    endDate: getFormattedDate(setOneYearAhead()),
    courseCode: "",
    courseTitle: "",
    irn: "",
    username: "",
  };

  useEffect(() => {
    handleGetWorkshops(workshopObjectToRetrieveOneYear);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleGetWorkshops = (workshopObject) => {
    props.getWorkshopList(workshopObject);
  };
  // -------------------------------------------------------------------------------------------------------------

  // Below code deals with Snackbar
  // -------------------------------------------------------------------------------------------------------------
  function renderSnackbar(snackbarType, message) {
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleRetryApiCall = () => {
    if (props.workshops.status !== 200) {
      handleGetWorkshops(workshopObjectToRetrieveOneYear);
    }
  };

  useEffect(() => {
    if (
      props.workshops.status !== undefined &&
      props.workshops.status !== 200
    ) {
      props.storeErrorMessages("Workshops information not found.", "workshops");
    } else {
      setWsOpenSuccess(false);
    }
  }, [props.workshops.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "workshops"
    ) {
      setWsOpenSuccess(true);
    }
  }, [props.errorMessages]); // eslint-disable-line react-hooks/exhaustive-deps
  // -------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card className={matches ? classes.desktopCard : classes.mobileCard}>
        <Box>
          <Typography
            className= {classes.titleBox}
            variant="subtitle1"
            component="h2"
            sx={{ letterSpacing: "2px", fontWeight: "500" }}
          >
            FACULTY WORKSHOPS
          </Typography>
        </Box>
        <CardContent
          className={
            matches ? classes.desktopCardContent : classes.mobileCardContent
          }
        >
         <div className = {classes.cardLinkContainer}>
          <CardLink
            externalLink={false}
            altText="Link"
            content="View upcoming workshops"
            link={"/facultyworkshops"}
          />
          </div>
          {workshopsToDisplay.length > 0 &&
            workshopsToDisplay.map((workshop) => (
              <>
                {incrementCount}
                <Typography
                  sx={{ color: "#DB3725", fontWeight: 700, padding: "0px" }}
                >
                  {`${formatDate(workshop.startDate)} - ${formatDate(
                    workshop.endDate
                  )}`}
                </Typography>
                <Typography component="h3" variant="subtitle2">
                  {workshop.title}
                </Typography>
                <Typography
                  sx={{
                    padding: "2px 0px",
                    fontSize: "14px",
                    fontStyle: "italic",
                    marginBottom: "24px",
                    color: "#68757c",
                  }}
                >
                  {`Registration deadline ${formatDate(
                    workshop.registerDeadline
                  )}`}
                </Typography>
              </>
            ))}
        </CardContent>
      </Card>

      {wsOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              props.errorMessages[0].displayMessage.message
            )
          : "")}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    workshops: state.workshops,
    errorMessages: state.errorMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWorkshopList: (workshopObject) =>
      dispatch(getWorkshopList(workshopObject)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkshopsCard);
