import styles from "./styles";
import { useMediaQuery, Box } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@material-ui/core";
import ProfileBox from "./ProfileBox";
import ProfileInfo from "./ProfileInfo";
import Logo from "./Logo";
import LinkBox from "./links/LinkBox";
import { connect } from "react-redux";
import SiteStripe from "./siteStripe/SiteStipe";

const Header = (props) => {
  //Allow for MUI styling
  const classes = styles();

  // Utilize useMediaQuery to create UI view for Mobile view
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return matches ? (
    <Grid
      container
      direction="column"
      className={classes.headerContainerMobile}
      role="banner"
    >
      <Grid item>
        <ProfileInfo />
      </Grid>
      <Grid item className={classes.navigateContainerMobile} role="navigation">
        <Logo />
        <LinkBox />
      </Grid>
      {/* {<Grid>
        <SiteStripe />
      </Grid>} */}
    </Grid>
  ) : (
    <>
      <Box className={classes.headerContainer} role="banner">
        <Grid className={classes.subheaderContainer}>
          <ProfileBox />
          <Logo />
          <LinkBox />
        </Grid>
        {/* {<Grid className={classes.subheaderContainer}>
          <SiteStripe />
        </Grid>} */}
      </Box>
    </>
  );
};

export default connect()(Header);
