import React from "react";
import { useEffect, useState } from "react";
import Logo from "./Logo";
import Frame2 from "./frame2/Frame2";
import Frame3 from "./frame2/Frame3";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import useStyles from "./Footer.styles";
import LinkBlock from "./linkBlock/LinkBlock";
import MobileLinkBlock from "./linkBlock/MobileLinkBlock";
import { connect } from "react-redux";
import { getSupervisor } from "../../redux/supervisor/supervisorActions";
import { getScheduler } from "../../redux/scheduler/schedulerActions";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContentWrapper from "../../components/SnackbarContentWrapper";
import { storeErrorMessages } from "../../redux/displayApiErrors/displayApiErrorsActions";
import { getTK20Authorization } from "../../services/authorization/v1/authorization";

const Footer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  // const matches = useMediaQuery(
  //   window.location.pathname === "/" || window.location.pathname === "/help"
  //     ? theme.breakpoints.down("md")
  //     : theme.breakpoints.down("sm")
  // );

  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const tabletMatches = useMediaQuery(theme.breakpoints.up("sm"));

  const [supervisorOpenSuccess, setSupervisorOpenSuccess] = useState(false);
  const [schedulerOpenSuccess, setSchedulerOpenSuccess] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [hasTK20Auth, setHasTK20Auth] = useState(false);
  const [wsUser, setWsUser] = useState(false);

  const tk20Url = encodeURI(`${process.env.REACT_APP_TK20_LTI_URL}`)

  useEffect(() => {
    if (props.demographics.issuerId) {
      props.getScheduler(props.demographics.issuerId);
      props.getSupervisor(props.demographics.issuerId);

      // props.getScheduler("FAKEIRN"); // fake IRNs for testing purposes.
      // props.getSupervisor("fakeIRN");
    }
  }, [props.demographics.issuerId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.supervisor.status !== undefined &&
      props.supervisor.status !== 200
    ) {
      props.storeErrorMessages(
        "Faculty supervisor contact information not found.",
        "supervisor"
      );
    } else {
      setSupervisorOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [props.supervisor.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.scheduler.status !== undefined &&
      props.scheduler.status !== 200
    ) {
      props.storeErrorMessages(
        "Scheduler contact information not found.",
        "scheduler"
      );
    } else {
      setSchedulerOpenSuccess(false);
      setOpenSuccess(false);
    }
  }, [props.scheduler.status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "supervisor"
    ) {
      setSupervisorOpenSuccess(true);
      setOpenSuccess(true);
    }
    if (
      props.errorMessages.length === 1 &&
      props.errorMessages[0].type.typeOfError === "scheduler"
    ) {
      setSchedulerOpenSuccess(true);
      setOpenSuccess(true);
    }
  }, [props.errorMessages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.demographics.username) {
      process.env.REACT_APP_WS_USERS_USERNAME_LIST.includes(
        props.demographics.username
      )
        ? setWsUser(true)
        : setWsUser(false);
    }
  }, [props.demographics.username]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSnackbar = (snackbarType, message) => (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSuccess}
        autoHideDuration={null}
        onClose={handleClose}
      >
        <SnackbarContentWrapper
          onClose={handleClose}
          handleRetryClick={handleRetryApiCall}
          variant={snackbarType}
          message={message}
        />
      </Snackbar>
    </>
  );

  const handleRetryApiCall = () => {
    if (props.demographics.personId) {
      if (props.supervisor.status !== 200) {
        props.getSupervisor(props.demographics.issuerId);
      }
      if (props.scheduler.status !== 200) {
        props.getScheduler(props.demographics.issuerId);
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const account = [
    {
      item: "Human Resources",
      link: `${process.env.REACT_APP_HUMAN_RESOURCES_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Change Password",
      link: `${process.env.REACT_APP_CHANGE_PASSWORD_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Faculty Credentials",
      link: `${process.env.REACT_APP_UPDATE_YOUR_CREDENTIALS_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Logout",
      link: `/logout`,
      authorization: true,
      display: true,
    },
  ];

  const facultyResources = [
    {
      item: "Faculty Workshops",
      link: "/facultyworkshops",
      authorization: true,
      display: (new Date() >= new Date(process.env.REACT_APP_TOGGLE_DATE) || wsUser),
    },
    {
      item: "PhoenixView Faculty",
      link: `${process.env.REACT_APP_PHOENIXVIEW_FACULTY_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Faculty Resources Center",
      link: `${process.env.REACT_APP_FACULTY_RESOURCES_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "SEOCS Results",
      link: `${process.env.REACT_APP_SEOCS_RESULTS_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Microsoft Office 365",
      link: `${process.env.REACT_APP_MICROSOFT_OFFICE_365_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Viva Engage",
      link: `${process.env.REACT_APP_VIVA_ENGAGE_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "University Learning Systems",
      link: `${process.env.REACT_APP_UNIVERSITY_LEARNING_SYSTEMS_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Submit Expenses",
      link: "/expensesubmissions",
      authorization: true,
      display: true,
    },
    {
      item: "Research Hub",
      link: `${process.env.REACT_APP_RESEARCH_HUB_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Assessment Management Tool",
      link: `${process.env.REACT_APP_SSO_CALLBACK_URL}${tk20Url}`,
      authorization: hasTK20Auth,
      display: true,
    },
    {
      item: "PhoenixStore",
      link: `${process.env.REACT_APP_PHOENIX_STORE_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Faculty Credentials Resources",
      link: `${process.env.REACT_APP_FACULTY_CREDENTIALS_RESOURCES}`,
      authorization: true,
      display: true,
    },
  ];

  const libraryResources = [
    {
      item: "University Library",
      link: `${process.env.REACT_APP_UNIVERSITY_LIBRARY_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Citation Generator",
      link: `${process.env.REACT_APP_CITATION_GENERATOR_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Center for Collaboration",
      link: `${process.env.REACT_APP_CENTER_FOR_COLLABORATION_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Center for Writing Excellence",
      link: `${process.env.REACT_APP_CENTER_FOR_WRITING_EXCELLENCE_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Center for Math Excellence",
      link: `${process.env.REACT_APP_CENTER_FOR_MATH_EXCELLENCE_URL}`,
      authorization: true,
      display: true,
    },
    {
      item: "Voter Registration",
      link: `${process.env.REACT_APP_VOTER_REGISTRATION_URL}`,
      authorization: true,
      display: true,
    },
  ];

  const connectBlock = [
    {
      item: "Faculty Help Desk",
      link: "/helpdesksubmission",
      authorization: true,
      display: true,
    },
    {
      item: "Faculty Supervisor",
      link:
        props.supervisor.businessEmail === undefined
          ? `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`
          : props.supervisor.businessEmail === null
          ? `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`
          : `mailto:${props.supervisor.businessEmail} `,
      authorization: true,
      display: true,
    },
    {
      item: "My Scheduler",
      link:
        props.scheduler.email === undefined
          ? `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`
          : props.scheduler.email === null
          ? `${process.env.REACT_APP_FACULTY_HELP_DESK_URL}`
          : `mailto:${props.scheduler.email} `,
      authorization: true,
      display: true,
    },
    {
      item: "Help & Support",
      link: "/help",
      authorization: true,
      display: true,
    },
  ];

  const getTK20Auth = async (profileId) => {
    await getTK20Authorization(profileId)
      .then((response) => {
        setHasTK20Auth(response.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (props.demographics.externalSystemIds) {
      getTK20Auth(props.demographics.externalSystemIds[`PROFILE_ID`][0]);
    }
  }, [props.demographics]);

  return matches ? (
    <>
      <div className={classes.bodyContainer}>
        <div className={classes.centerTopContainer} role="contentinfo">
          <Box className={classes.topContainer}>
            <LinkBlock title="Account" listItem={account} />
            <LinkBlock title="Faculty Resources" listItem={facultyResources} />
            <LinkBlock title="Library Resources" listItem={libraryResources} />
            <LinkBlock title="Connect" listItem={connectBlock} />
          </Box>
          <Box className={classes.bottomContainer}>
            <Logo />
            <Frame2 />
            <Frame3 />
          </Box>
        </div>
        {supervisorOpenSuccess &&
          (props.errorMessages.length === 1
            ? renderSnackbar(
                "error",
                "Faculty supervisor contact information not found."
              )
            : "")}
        {schedulerOpenSuccess &&
          (props.errorMessages.length === 1
            ? renderSnackbar(
                "error",
                "Scheduler contact information not found."
              )
            : "")}
      </div>
    </>
  ) : tabletMatches ? (
    <>
      <div className={classes.mobileCardWrapper} role="contentinfo">
        <Grid container>
          <Grid item xs={12} md={12}>
            <MobileLinkBlock
              title="Account"
              itemList={account}
            />
            <MobileLinkBlock
              title="Faculty Resources"
              itemList={facultyResources}
            />
            <MobileLinkBlock
              title="Library Resources"
              itemList={libraryResources}
            />
            <MobileLinkBlock title="Connect" itemList={connectBlock} />
          </Grid>
          <Grid item>
            <Box className={classes.bottomContainerTablet}>
              <Logo />
              <Frame2 />
              <Frame3 />
            </Box>
          </Grid>
        </Grid>
      </div>
      {supervisorOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              "Faculty supervisor contact information not found."
            )
          : "")}
      {schedulerOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar("error", "Scheduler contact information not found.")
          : "")}
    </>
  ) : (
    <>
      <div className={classes.mobileCardWrapper} role="contentinfo">
        <Grid container>
          <Grid item xs={12} md={12}>
            <MobileLinkBlock
             title="Account"
             itemList={account}
           />
            <MobileLinkBlock
              title="Faculty Resources"
              itemList={facultyResources}
            />
            <MobileLinkBlock
              title="Library Resources"
              itemList={libraryResources}
            />
            <MobileLinkBlock title="Connect" itemList={connectBlock} />
          </Grid>
          <Grid item>
            <Frame2 />
          </Grid>
        </Grid>
      </div>
      {supervisorOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar(
              "error",
              "Faculty supervisor contact information not found."
            )
          : "")}
      {schedulerOpenSuccess &&
        (props.errorMessages.length === 1
          ? renderSnackbar("error", "Scheduler contact information not found.")
          : "")}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    demographics: state.demographics,
    scheduler: state.scheduler,
    supervisor: state.supervisor,
    errorMessages: state.errorMessages,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getScheduler: (irn) => dispatch(getScheduler(irn)),
    getSupervisor: (irn) => dispatch(getSupervisor(irn)),
    storeErrorMessages: (message, typeOfError) =>
      dispatch(storeErrorMessages(message, typeOfError)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
