import { getCaseTypesOptions, getIssueTypesOptions} from "../../services/sfapi/v1/sfApiHelpDesk";
import { GET_CASETYPES, GET_ISSUETYPES } from "./actionTypes";

export const getCaseTypes = () => async (dispatch) => {
  await  getCaseTypesOptions()
    .then((response) => {
      dispatch({ type: GET_CASETYPES, payload: response.data });
    })

    .catch((error) => {
      dispatch({
        type: GET_CASETYPES,
        payload: error.request,
      });
    });
};

export const getIssueTypes = (caseType) => async (dispatch) => {
    await  getIssueTypesOptions(caseType)
      .then((response) => {
        dispatch({ type: GET_ISSUETYPES, payload: response.data });
      })
  
      .catch((error) => {
        dispatch({
          type: GET_ISSUETYPES,
          payload: error.request,
        });
      });
  };