import { proxyClientInstance } from "../../wrappers/client";

  export const getCaseTypesOptions = () => {
    const getUrl = `/api/faculty/v1/integrations/fms/facultyhelpdesk/case/subtypes?subtype=Faculty%20Help%20Desk`;
    return proxyClientInstance.get(getUrl);
  };

  export const getIssueTypesOptions = (caseType) => {
    const getUrl = `/api/faculty/v1/integrations/fms/facultyhelpdesk/case/issuetypes?subtype=${caseType}`;
    return proxyClientInstance.get(getUrl);
  };